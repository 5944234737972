@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700);
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: "Poppins" !important;
}

#root {
  height: 100%;
  display: flex;
}

#topbar {
  padding: 5px 10px;
  background-color: #e9fff5;
  border-bottom: 1px solid #3db981;
}

.tryBtn {
  padding: 0.25rem 0.5rem;
  margin-right: 0.5rem;
  cursor: pointer;
  font-size: 1rem;
  border: 1px solid black;
}
